import { cn } from "~/plugins/cn";
import type { ClassValue } from "clsx";

const highlightText = (word: string, query: string, classes?: ClassValue): string => {
  if (!word || !(query && query.length)) return word;

  classes = cn("bg-gray-100 transition-colors", classes);

  const ESCAPED_QUERY = query.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
  let check = new RegExp(ESCAPED_QUERY, "ig");
  return word.toString().replace(check, (matchedText) => `<span class="${classes}">${matchedText}</span>`);
};

export default highlightText;
